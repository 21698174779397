import { defineMessages } from 'react-intl';

import { ServiceType } from 'protocol/api/billing/dto_services_pb';
import { ServiceGroupName } from 'protocol/api/billing_new/dto_services_new_pb';
import { FeatureFlag, OrganizationType } from 'protocol/model/dto_organization_pb';

export const modal = defineMessages({
  yes: {
    id: 'modal.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'modal.no',
    defaultMessage: 'No',
  },
});

export const lifeTimeMonthDefaultMessages = defineMessages<number>({
  1: {
    id: 'lifetime.month.1',
    defaultMessage: 'Monthly',
  },
  3: {
    id: 'lifetime.month.3',
    defaultMessage: '3 months',
  },
  6: {
    id: 'lifetime.month.6',
    defaultMessage: '6 months',
  },
  12: {
    id: 'lifetime.month.12',
    defaultMessage: 'Annual',
  },
});

export const countries = defineMessages({
  KG: {
    id: 'country.KG',
    defaultMessage: 'Kyrgyzstan',
    ru: '\u041a\u0438\u0440\u0433\u0438\u0437\u0438\u044f',
  },
  HR: {
    id: 'country.HR',
    defaultMessage: 'Croatia',
    ru: '\u0425\u043e\u0440\u0432\u0430\u0442\u0438\u044f',
  },
  AT: {
    id: 'country.AT',
    defaultMessage: 'Austria',
    ru: '\u0410\u0432\u0441\u0442\u0440\u0438\u044f',
  },
  SI: {
    id: 'country.SI',
    defaultMessage: 'Slovenia',
    ru: '\u0421\u043b\u043e\u0432\u0435\u043d\u0438\u044f',
  },
  MT: {
    id: 'country.MT',
    defaultMessage: 'Malta',
    ru: '\u041c\u0430\u043b\u044c\u0442\u0430',
  },
  CY: {
    id: 'country.CY',
    defaultMessage: 'Cyprus',
    ru: '\u041a\u0438\u043f\u0440',
  },
  SK: {
    id: 'country.SK',
    defaultMessage: 'Slovakia',
    ru: '\u0421\u043b\u043e\u0432\u0430\u043a\u0438\u044f',
  },
  PL: {
    id: 'country.PL',
    defaultMessage: 'Poland',
    ru: '\u041f\u043e\u043b\u044c\u0448\u0430',
  },
  PS: {
    id: 'country.PS',
    defaultMessage: 'Palestinian Territories',
    ru: '\u041f\u0430\u043b\u0435\u0441\u0442\u0438\u043d\u0441\u043a\u0438\u0435 \u0442\u0435\u0440\u0440\u0438\u0442\u043e\u0440\u0438\u0438',
  },
  CZ: {
    id: 'country.CZ',
    defaultMessage: 'Czechia',
    ru: '\u0427\u0435\u0445\u0438\u044f',
  },
  HU: {
    id: 'country.HU',
    defaultMessage: 'Hungary',
    ru: '\u0412\u0435\u043d\u0433\u0440\u0438\u044f',
  },
  VI: {
    id: 'country.VI',
    defaultMessage: 'U.S. Virgin Islands',
    ru: '\u0412\u0438\u0440\u0433\u0438\u043d\u0441\u043a\u0438\u0435 \u043e-\u0432\u0430 (\u0421\u0428\u0410)',
  },
  AM: {
    id: 'country.AM',
    defaultMessage: 'Armenia',
    ru: '\u0410\u0440\u043c\u0435\u043d\u0438\u044f',
  },
  GB: {
    id: 'country.GB',
    defaultMessage: 'United Kingdom',
    ru: '\u0412\u0435\u043b\u0438\u043a\u043e\u0431\u0440\u0438\u0442\u0430\u043d\u0438\u044f',
  },
  EE: {
    id: 'country.EE',
    defaultMessage: 'Estonia',
    ru: '\u042d\u0441\u0442\u043e\u043d\u0438\u044f',
  },
  SE: {
    id: 'country.SE',
    defaultMessage: 'Sweden',
    ru: '\u0428\u0432\u0435\u0446\u0438\u044f',
  },
  PR: {
    id: 'country.PR',
    defaultMessage: 'Puerto Rico',
    ru: '\u041f\u0443\u044d\u0440\u0442\u043e-\u0420\u0438\u043a\u043e',
  },
  UZ: {
    id: 'country.UZ',
    defaultMessage: 'Uzbekistan',
    ru: '\u0423\u0437\u0431\u0435\u043a\u0438\u0441\u0442\u0430\u043d',
  },
  MD: {
    id: 'country.MD',
    defaultMessage: 'Moldova',
    ru: '\u041c\u043e\u043b\u0434\u043e\u0432\u0430',
  },
  CO: {
    id: 'country.CO',
    defaultMessage: 'Colombia',
    ru: '\u041a\u043e\u043b\u0443\u043c\u0431\u0438\u044f',
  },
  BE: {
    id: 'country.BE',
    defaultMessage: 'Belgium',
    ru: '\u0411\u0435\u043b\u044c\u0433\u0438\u044f',
  },
  FI: {
    id: 'country.FI',
    defaultMessage: 'Finland',
    ru: '\u0424\u0438\u043d\u043b\u044f\u043d\u0434\u0438\u044f',
  },
  FR: {
    id: 'country.FR',
    defaultMessage: 'France',
    ru: '\u0424\u0440\u0430\u043d\u0446\u0438\u044f',
  },
  RO: {
    id: 'country.RO',
    defaultMessage: 'Romania',
    ru: '\u0420\u0443\u043c\u044b\u043d\u0438\u044f',
  },
  MX: {
    id: 'country.MX',
    defaultMessage: 'Mexico',
    ru: '\u041c\u0435\u043a\u0441\u0438\u043a\u0430',
  },
  BY: {
    id: 'country.BY',
    defaultMessage: 'Belarus',
    ru: '\u0411\u0435\u043b\u0430\u0440\u0443\u0441\u044c',
  },
  GU: {
    id: 'country.GU',
    defaultMessage: 'Guam',
    ru: '\u0413\u0443\u0430\u043c',
  },
  TJ: {
    id: 'country.TJ',
    defaultMessage: 'Tajikistan',
    ru: '\u0422\u0430\u0434\u0436\u0438\u043a\u0438\u0441\u0442\u0430\u043d',
  },
  GE: {
    id: 'country.GE',
    defaultMessage: 'Georgia',
    ru: '\u0413\u0440\u0443\u0437\u0438\u044f',
  },
  RU: {
    id: 'country.RU',
    defaultMessage: 'Russia',
    ru: '\u0420\u043e\u0441\u0441\u0438\u044f',
  },
  IE: {
    id: 'country.IE',
    defaultMessage: 'Ireland',
    ru: '\u0418\u0440\u043b\u0430\u043d\u0434\u0438\u044f',
  },
  IL: {
    id: 'country.IL',
    defaultMessage: 'Israel',
    ru: '\u0418\u0437\u0440\u0430\u0438\u043b\u044c',
  },
  NO: {
    id: 'country.NO',
    defaultMessage: 'Norway',
    ru: '\u041d\u043e\u0440\u0432\u0435\u0433\u0438\u044f',
  },
  DK: {
    id: 'country.DK',
    defaultMessage: 'Denmark',
    ru: '\u0414\u0430\u043d\u0438\u044f',
  },
  US: {
    id: 'country.US',
    defaultMessage: 'United States',
    ru: '\u0421\u043e\u0435\u0434\u0438\u043d\u0435\u043d\u043d\u044b\u0435 \u0428\u0442\u0430\u0442\u044b',
  },
  GR: {
    id: 'country.GR',
    defaultMessage: 'Greece',
    ru: '\u0413\u0440\u0435\u0446\u0438\u044f',
  },
  CA: {
    id: 'country.CA',
    defaultMessage: 'Canada',
    ru: '\u041a\u0430\u043d\u0430\u0434\u0430',
  },
  PT: {
    id: 'country.PT',
    defaultMessage: 'Portugal',
    ru: '\u041f\u043e\u0440\u0442\u0443\u0433\u0430\u043b\u0438\u044f',
  },
  DE: {
    id: 'country.DE',
    defaultMessage: 'Germany',
    ru: '\u0413\u0435\u0440\u043c\u0430\u043d\u0438\u044f',
  },
  LV: {
    id: 'country.LV',
    defaultMessage: 'Latvia',
    ru: '\u041b\u0430\u0442\u0432\u0438\u044f',
  },
  AU: {
    id: 'country.AU',
    defaultMessage: 'Australia',
    ru: '\u0410\u0432\u0441\u0442\u0440\u0430\u043b\u0438\u044f',
  },
  NZ: {
    id: 'country.NZ',
    defaultMessage: 'New Zealand',
    ru: '\u041d\u043e\u0432\u0430\u044f \u0417\u0435\u043b\u0430\u043d\u0434\u0438\u044f',
  },
  LU: {
    id: 'country.LU',
    defaultMessage: 'Luxembourg',
    ru: '\u041b\u044e\u043a\u0441\u0435\u043c\u0431\u0443\u0440\u0433',
  },
  AS: {
    id: 'country.AS',
    defaultMessage: 'American Samoa',
    ru: '\u0410\u043c\u0435\u0440\u0438\u043a\u0430\u043d\u0441\u043a\u043e\u0435 \u0421\u0430\u043c\u043e\u0430',
  },
  AZ: {
    id: 'country.AZ',
    defaultMessage: 'Azerbaijan',
    ru: '\u0410\u0437\u0435\u0440\u0431\u0430\u0439\u0434\u0436\u0430\u043d',
  },
  KZ: {
    id: 'country.KZ',
    defaultMessage: 'Kazakhstan',
    ru: '\u041a\u0430\u0437\u0430\u0445\u0441\u0442\u0430\u043d',
  },
  CH: {
    id: 'country.CH',
    defaultMessage: 'Switzerland',
    ru: '\u0428\u0432\u0435\u0439\u0446\u0430\u0440\u0438\u044f',
  },
  LT: {
    id: 'country.LT',
    defaultMessage: 'Lithuania',
    ru: '\u041b\u0438\u0442\u0432\u0430',
  },
  IT: {
    id: 'country.IT',
    defaultMessage: 'Italy',
    ru: '\u0418\u0442\u0430\u043b\u0438\u044f',
  },
  NL: {
    id: 'country.NL',
    defaultMessage: 'Netherlands',
    ru: '\u041d\u0438\u0434\u0435\u0440\u043b\u0430\u043d\u0434\u044b',
  },
  ES: {
    id: 'country.ES',
    defaultMessage: 'Spain',
    ru: '\u0418\u0441\u043f\u0430\u043d\u0438\u044f',
  },
  UA: {
    id: 'country.UA',
    defaultMessage: 'Ukraine',
    ru: '\u0423\u043a\u0440\u0430\u0438\u043d\u0430',
  },
});

export const ServiceNamesDefaultMessage = defineMessages<ServiceType>({
  [ServiceType.ServiceTypeInvalidValue]: {
    id: 'subscriptions.service.0',
    defaultMessage: 'Invalid Value',
  },
  [ServiceType.ServiceType_Upload_CBCT]: {
    id: 'subscriptions.service.1',
    defaultMessage: 'Upload CBCT',
  },
  [ServiceType.ServiceType_Upload_IOXRay]: {
    id: 'subscriptions.service.2',
    defaultMessage: 'Upload Intraoral X-Ray',
  },
  [ServiceType.ServiceType_Upload_Panorama]: {
    id: 'subscriptions.service.3',
    defaultMessage: 'Upload Panoramic',
  },
  [ServiceType.ServiceType_Upload_STL]: {
    id: 'subscriptions.service.4',
    defaultMessage: 'STL Uploads',
  },
  [ServiceType.ServiceType_Upload_DentalPhoto]: {
    id: 'subscriptions.service.5',
    defaultMessage: 'Dental Photo Uploads',
  },
  [ServiceType.ServiceType_Upload_Documents]: {
    id: 'subscriptions.service.6',
    defaultMessage: 'Document Uploads',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_GP_Analysis]: {
    id: 'subscriptions.service.100',
    defaultMessage:
      '{amount, plural, =0 {Radiology Report} one {{amount} Radiology Report} other {{amount} Radiology Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_Endodontic_Analysis]: {
    id: 'subscriptions.service.101',
    defaultMessage:
      '{amount, plural, =0 {Endo Report} one {{amount} Endo Report} other {{amount} Endo Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_Implantology_Analysis]: {
    id: 'subscriptions.service.102',
    defaultMessage:
      '{amount, plural, =0 {Implant Report} one {{amount} Implant Report} other {{amount} Implant Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_ThirdMolar_Analysis]: {
    id: 'subscriptions.service.103',
    defaultMessage:
      '{amount, plural, =0 {3rd Molar Report} one {{amount} 3rd Molar Report} other {{amount} 3rd Molar Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_STL_Analysis]: {
    id: 'subscriptions.service.104',
    defaultMessage:
      '{amount, plural, =0 {DICOM to STL conversion} one {{amount} DICOM to STL conversion} other {{amount} DICOM to STL conversions}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_Orthodontic_Analysis]: {
    id: 'subscriptions.service.105',
    defaultMessage:
      '{amount, plural, =0 {Ortho Report} one {{amount} Ortho Report} other {{amount} Ortho Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis]: {
    id: 'subscriptions.service.106',
    defaultMessage:
      '{amount, plural, =0 {CBCT IOS Superimposition} other {{amount} CBCT IOS Superimposition}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_IOS_Segmentation_Analysis]: {
    id: 'subscriptions.service.107',
    defaultMessage:
      '{amount, plural, =0 {IOS Segmentation} one {{amount} IOS Segmentation} other {{amount} IOS Segmentations}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_IOXRay_GP_Analysis]: {
    id: 'subscriptions.service.108',
    defaultMessage:
      '{amount, plural, =0 {FMX AI Report} one {{amount} FMX AI Report} other {{amount} FMX AI Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_Panoramic_GP_Analysis]: {
    id: 'subscriptions.service.109',
    defaultMessage:
      '{amount, plural, =0 {Pano AI Report} one {{amount} Pano AI Report} other {{amount} Pano AI Reports}}',
  },
  // Both countable and enabler
  [ServiceType.ServiceType_Order_DentalPhoto_GP_Analysis]: {
    id: 'subscriptions.service.110',
    defaultMessage:
      '{amount, plural, =0 {Dental Photo Classification} one {{amount} Dental Photo Classification} other {{amount} Dental Photo Classification}}',
  },
  [ServiceType.ServiceType_Order_ImplantStudio_Analysis]: {
    id: 'subscriptions.service.111',
    defaultMessage: 'Implant studio analysis',
  },
  [ServiceType.ServiceType_Order_OrthoStudio_Analysis]: {
    id: 'subscriptions.service.112',
    defaultMessage: 'Ortho studio analysis',
  },
  [ServiceType.ServiceType_Group_Order_3D_Analysis]: {
    id: 'subscriptions.service.200',
    defaultMessage: 'Order 3D Analysis',
  },
  [ServiceType.ServiceType_Group_Order_2D_Analysis]: {
    id: 'subscriptions.service.201',
    defaultMessage: 'Order 2D Analysis',
  },
  [ServiceType.ServiceType_Group_PrimaryStorage]: {
    id: 'subscriptions.service.202',
    defaultMessage: 'Primary Storage',
  },
  [ServiceType.ServiceType_Group_Upload_3D]: {
    id: 'subscriptions.service.203',
    defaultMessage: 'Upload 3D',
  },
  [ServiceType.ServiceType_Group_Upload_2D]: {
    id: 'subscriptions.service.204',
    defaultMessage: 'Upload 2D',
  },
  // Only enabler
  [ServiceType.ServiceType_Enable_CollaborationTool]: {
    id: 'subscriptions.service.300',
    defaultMessage: 'Collaboration Tool',
  },
  // Only enabler
  [ServiceType.ServiceType_Enable_STLViewer]: {
    id: 'subscriptions.service.301',
    defaultMessage: 'STL Viewer',
  },
  [ServiceType.ServiceType_Enable_DesktopApp]: {
    id: 'subscriptions.service.302',
    defaultMessage: 'Desktop application',
  },
});

export const GroupNamesDefaultMessage = defineMessages<ServiceGroupName>({
  [ServiceGroupName.ServiceGroupNameInvalidValue]: {
    id: 'global.invalidValue',
    defaultMessage: 'Invalid value',
  },
  [ServiceGroupName.ServiceGroupNameEmpty]: {
    id: 'subscriptions.group.1',
    defaultMessage: 'Empty group',
  },
  [ServiceGroupName.ServiceGroupNameAnalysis3D]: {
    id: 'subscriptions.group.2',
    defaultMessage: '3D Reports',
  },
  [ServiceGroupName.ServiceGroupNameAnalysis2D]: {
    id: 'subscriptions.group.3',
    defaultMessage: '2D Reports',
  },
  [ServiceGroupName.ServiceGroupNameUpload3D]: {
    id: 'subscriptions.group.4',
    defaultMessage: '3D Uploads',
  },
  [ServiceGroupName.ServiceGroupNameUpload2D]: {
    id: 'subscriptions.group.5',
    defaultMessage: '2D Uploads',
  },
});

export const organizationTypeDefaultMessages = defineMessages<OrganizationType>(
  {
    [OrganizationType.OrganizationTypeClinic]: {
      id: 'organizationType.organizationTypeClinic',
      defaultMessage: 'Clinic',
    },
    [OrganizationType.OrganizationTypeDiagnosticCenter]: {
      id: 'organizationType.organizationTypeDiagnosticCenter',
      defaultMessage: 'Diagnostic center',
    },
    [OrganizationType.OrganizationTypeSoloPractitioner]: {
      id: 'organizationType.organizationTypeSoloPractitioner',
      defaultMessage: 'Solo practitioner',
    },
    [OrganizationType.OrganizationTypeInvalidValue]: {
      id: 'organizationType.OrganizationTypeInvalidValue',
      defaultMessage: 'Invalid value',
    },
  },
);

export const featureFlagsDefaultMessages = defineMessages<FeatureFlag>({
  [FeatureFlag.FeatureFlag_InvalidValue]: {
    id: 'featureFlag.name.invalidValue',
    defaultMessage: 'Invalid feature flag',
  },
  [FeatureFlag.FeatureFlag_Show_Order3DReports]: {
    id: 'featureFlag.name.showOrder3DReports',
    defaultMessage: 'Show order 3D reports',
  },
  [FeatureFlag.FeatureFlag_Show_BillingShop]: {
    id: 'featureFlag.name.showBillingShop',
    defaultMessage: 'Show billing shop',
  },
  [FeatureFlag.FeatureFlag_Show_NonDentalFindings]: {
    id: 'featureFlag.name.showNonDentalFindings',
    defaultMessage: 'Show non dental findings',
  },
  [FeatureFlag.FeatureFlag_Hide_Probabilities]: {
    id: 'featureFlag.name.hideProbabilities',
    defaultMessage: 'Hide probabilities',
  },
  [FeatureFlag.FeatureFlag_Hide_ConditionsMasks]: {
    id: 'featureFlag.name.hideConditionsMasks',
    defaultMessage: 'Hide conditions',
  },
  [FeatureFlag.FeatureFlag_Hide_PerioMasksAndSlices]: {
    id: 'featureFlag.name.hidePerioMasks',
    defaultMessage: 'Hide perio masks',
  },
  [FeatureFlag.FeatureFlag_Hide_CariesSigns]: {
    id: 'featureFlag.name.hideCariesSigns',
    defaultMessage: 'Hide caries signs',
  },
  [FeatureFlag.FeatureFlag_Hide_PBLRulesAndMeasurements]: {
    id: 'featureFlag.name.hidePBLRulesAndMeasurements',
    defaultMessage: 'Hide PBL rules and measurements',
  },
  [FeatureFlag.FeatureFlag_Show_ImplantPlanningButton]: {
    id: 'featureFlag.name.showImplantPlanningButton',
    defaultMessage: 'Show implant planning button',
  },
  [FeatureFlag.FeatureFlag_Show_OrthoPlanningButton]: {
    id: 'featureFlag.name.showOrthoPlanningButton',
    defaultMessage: 'Show ortho planning button',
  },
});
