import React, { FC, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Radio,
  Row,
  Space,
  Typography,
} from 'antd';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';

import { Layout } from '@/shared/ui';
import { Header } from '@/widgets/header';
import { Footer } from '@/widgets/footer';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import {
  billingModel,
  useAccountInfoStream,
  EffectToAddType,
} from '@/entities/billing';
import { PATHS } from '@/shared/config';
import { shouldAskAdditionalInfo } from '@/shared/lib/shouldAskAdditionalData';
import { AdditionalDataForm } from '@/features/additionalDataForm';

const { Title } = Typography;

type AddSubscriptionFormType = {
  subscriptionID?: string;
  lifeTimeMonths?: number;
};

const selectNewOrderLoadingState =
  billingModel.selectors.getLoadingStateSelector('newOrder');

export const AddSubscription: FC = () => {
  const [isAdditionalFormOpen, setIsAdditionalFormOpen] =
    useState<boolean>(false);

  const { organizationID } = useParams<{ organizationID: string }>();
  // TODO: [2|m] think about account info stream in upper scope to prevent reopening stream
  useAccountInfoStream(organizationID);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const subscriptions = useAppSelector(
    billingModel.selectors.selectAvailableSubscriptions,
  );

  const newOrderLoadingState = useAppSelector(selectNewOrderLoadingState);

  const {
    ID: accountID,
    BillingZones,
    BillingInformation,
  } = useAppSelector(billingModel.selectors.selectAccountInfo);

  const BillingZone = BillingZones?.at(0);

  const { formatMessage } = useIntl();
  // TODO [2|l] devtools throw warning that form doesnt connect to any Form component but its connected. Need to solve this issue
  const [form] = Form.useForm<AddSubscriptionFormType>();

  Form.useWatch('subscriptionID', form);

  const selectedSubscriptionID = form.getFieldValue('subscriptionID');

  const currentSubscription = subscriptions.find(
    ({ SKU }) => SKU === selectedSubscriptionID,
  );

  const onFinish = async () => {
    const effectToAdd: EffectToAddType = {
      case: 'SubscriptionAdd',
      value: {
        Subscription: currentSubscription,
        IsAutoRenewal: true,
      },
    };

    if (shouldAskAdditionalInfo(BillingZone, BillingInformation)) {
      dispatch(billingModel.actions.setEffectToAdd(effectToAdd));

      setIsAdditionalFormOpen(true);
    } else {
      try {
        dispatch(
          billingModel.actions.setLoading({
            loaderKey: 'newOrder',
            loaderState: 'pending',
          }),
        );

        await dispatch(
          billingModel.thunks.createOrder({
            AccountID: accountID,
            Effect: effectToAdd,
          }),
        );

        navigate(generatePath(PATHS.organization, { organizationID }));
      } catch {
        dispatch(
          billingModel.actions.setLoading({
            loaderKey: 'newOrder',
            loaderState: 'failed',
          }),
        );
      } finally {
        dispatch(
          billingModel.actions.setLoading({
            loaderKey: 'newOrder',
            loaderState: 'idle',
          }),
        );
      }
    }
  };

  useEffect(() => {
    const getProducts = async () => {
      const { AvailableProducts } = await dispatch(
        billingModel.thunks.getAvailableProducts({
          Condition: {
            case: 'ByAccountID',
            value: accountID,
          },
        }),
      ).unwrap();

      dispatch(billingModel.actions.setAvailableProducts(AvailableProducts));
    };

    if (accountID) {
      getProducts();
    }
  }, [accountID]);

  return (
    <Layout>
      <Header />

      <Layout.Content>
        <Breadcrumb
          style={{ padding: '24px 0' }}
          items={[
            {
              title: (
                <Link to={generatePath(PATHS.main)}>
                  <FormattedMessage
                    id="breadcrumb.home"
                    defaultMessage="Home"
                  />
                </Link>
              ),
            },
            {
              title: (
                <Link to={generatePath(PATHS.organization, { organizationID })}>
                  <FormattedMessage
                    id="breadcrumb.organizationProfile"
                    defaultMessage="Organization profile"
                  />
                </Link>
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="newSubscriptionPage.title"
                  defaultMessage="New subscription"
                />
              ),
            },
          ]}
        />

        <Form
          form={form}
          name="add-subscription"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          onValuesChange={(values) => {
            if (values?.subscriptionID) {
              form.setFieldsValue({ lifeTimeMonths: undefined });
            }
          }}
        >
          <Row gutter={32}>
            <Col span={12}>
              {/* Step 1 */}
              <Title level={4}>
                <FormattedMessage
                  id="subscriptionForm.stepOne"
                  defaultMessage="Select subscription"
                />
              </Title>

              <Form.Item
                name="subscriptionID"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'forms.required',
                      defaultMessage: 'Field is required',
                    }),
                  },
                ]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {subscriptions.map(
                      ({ SKU, Name, GroupName, Price, Currency }) => (
                        <Radio key={SKU} value={SKU}>
                          {GroupName ? `${GroupName}: ` : ''}
                          {Name}
                          {' | '}
                          <FormattedNumber
                            maximumFractionDigits={0}
                            value={Number(Price ?? '0')}
                            // eslint-disable-next-line react/style-prop-object
                            style="currency"
                            currencyDisplay="narrowSymbol"
                            currency={Currency}
                          />
                        </Radio>
                      ),
                    )}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            loading={newOrderLoadingState === 'pending'}
          >
            <span>
              <FormattedMessage
                id="subscriptionForm.addNewSubscription"
                defaultMessage="Add new subscription"
              />
            </span>
          </Button>
        </Form>

        <AdditionalDataForm
          isOpen={isAdditionalFormOpen}
          onCloseHandler={() => setIsAdditionalFormOpen(false)}
        />
      </Layout.Content>

      <Footer />
    </Layout>
  );
};
