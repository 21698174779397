// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/billing_new/svc_billing_new.proto (package com.diagnocat.billing_new, syntax proto3)
/* eslint-disable */

import { AccountInfoStreamReq, AccountInfoStreamResp, CreateAccountPaymentMethodReq, CreateAccountPaymentMethodResp, CreateOrderReq, CreateOrderResp, DeleteAccountPaymentMethodReq, DeleteAccountPaymentMethodResp, GetAllAvailableProductsReq, GetAllAvailableProductsResp, GetAvailableProductsReq, GetAvailableProductsResp, GetPaymentDocumentByOrderReq, GetPaymentDocumentByOrderResp, PayOrderReq, PayOrderResp, ProcessOrderReq, ProcessOrderResp, SetAccountBillingInformationReq, SetAccountBillingInformationResp, SetAccountBillingZonesReq, SetAccountBillingZonesResp, SetTransactionNumberToOrderReq, SetTransactionNumberToOrderResp, Test_SetAccountWithoutStripeInvoicesReq, Test_SetAccountWithoutStripeInvoicesResp } from "./svc_billing_new_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.diagnocat.billing_new.Billing
 */
export const Billing = {
  typeName: "com.diagnocat.billing_new.Billing",
  methods: {
    /**
     *  Get stream client with account info
     *
     * @generated from rpc com.diagnocat.billing_new.Billing.AccountInfoStream
     */
    accountInfoStream: {
      name: "AccountInfoStream",
      I: AccountInfoStreamReq,
      O: AccountInfoStreamResp,
      kind: MethodKind.ServerStreaming,
    },
    /**
     *  Get available product objects by available product id or geo zone or account id
     *
     * @generated from rpc com.diagnocat.billing_new.Billing.GetAvailableProducts
     */
    getAvailableProducts: {
      name: "GetAvailableProducts",
      I: GetAvailableProductsReq,
      O: GetAvailableProductsResp,
      kind: MethodKind.Unary,
    },
    /**
     *  Get all available products (salesman only)
     *
     * @generated from rpc com.diagnocat.billing_new.Billing.GetAllAvailableProducts
     */
    getAllAvailableProducts: {
      name: "GetAllAvailableProducts",
      I: GetAllAvailableProductsReq,
      O: GetAllAvailableProductsResp,
      kind: MethodKind.Unary,
    },
    /**
     *  Create order
     *
     * @generated from rpc com.diagnocat.billing_new.Billing.CreateOrder
     */
    createOrder: {
      name: "CreateOrder",
      I: CreateOrderReq,
      O: CreateOrderResp,
      kind: MethodKind.Unary,
    },
    /**
     *  Process order by id (salesman only)
     *
     * @generated from rpc com.diagnocat.billing_new.Billing.ProcessOrder
     */
    processOrder: {
      name: "ProcessOrder",
      I: ProcessOrderReq,
      O: ProcessOrderResp,
      kind: MethodKind.Unary,
    },
    /**
     *  Pay order (owner only)
     *
     * @generated from rpc com.diagnocat.billing_new.Billing.PayOrder
     */
    payOrder: {
      name: "PayOrder",
      I: PayOrderReq,
      O: PayOrderResp,
      kind: MethodKind.Unary,
    },
    /**
     *  Get stripe's page with payment method creation
     *
     * @generated from rpc com.diagnocat.billing_new.Billing.CreateAccountPaymentMethod
     */
    createAccountPaymentMethod: {
      name: "CreateAccountPaymentMethod",
      I: CreateAccountPaymentMethodReq,
      O: CreateAccountPaymentMethodResp,
      kind: MethodKind.Unary,
    },
    /**
     *  Delete account payment method by stripe's id
     *
     * @generated from rpc com.diagnocat.billing_new.Billing.DeleteAccountPaymentMethod
     */
    deleteAccountPaymentMethod: {
      name: "DeleteAccountPaymentMethod",
      I: DeleteAccountPaymentMethodReq,
      O: DeleteAccountPaymentMethodResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.billing_new.Billing.SetAccountBillingInformation
     */
    setAccountBillingInformation: {
      name: "SetAccountBillingInformation",
      I: SetAccountBillingInformationReq,
      O: SetAccountBillingInformationResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.billing_new.Billing.GetPaymentDocumentByOrder
     */
    getPaymentDocumentByOrder: {
      name: "GetPaymentDocumentByOrder",
      I: GetPaymentDocumentByOrderReq,
      O: GetPaymentDocumentByOrderResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.billing_new.Billing.SetTransactionNumberToOrder
     */
    setTransactionNumberToOrder: {
      name: "SetTransactionNumberToOrder",
      I: SetTransactionNumberToOrderReq,
      O: SetTransactionNumberToOrderResp,
      kind: MethodKind.Unary,
    },
    /**
     * (salesman only)
     *
     * @generated from rpc com.diagnocat.billing_new.Billing.SetAccountBillingZones
     */
    setAccountBillingZones: {
      name: "SetAccountBillingZones",
      I: SetAccountBillingZonesReq,
      O: SetAccountBillingZonesResp,
      kind: MethodKind.Unary,
    },
    /**
     *  Only for QA
     *
     * @generated from rpc com.diagnocat.billing_new.Billing.Test_SetAccountWithoutStripeInvoices
     */
    test_SetAccountWithoutStripeInvoices: {
      name: "Test_SetAccountWithoutStripeInvoices",
      I: Test_SetAccountWithoutStripeInvoicesReq,
      O: Test_SetAccountWithoutStripeInvoicesResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

